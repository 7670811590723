/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FaFilter, FaRegStopCircle, FaUsers } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { DateText } from './styles'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex, FormControl, useToast } from '@chakra-ui/react'
import { CardAvaliado } from './Components/CardAvaliado'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { NavLine } from '../../../components/NavLine'
import { PropInvalida, ReturnUniqueByKey } from '../../../Utils/Helper'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ModalConfiguracao } from './Components/ModalConfiguracao'
import { ModalReabrir } from '../../../components/ModalReabrir'
import { CardAvaliadoCiclico } from './Components/CardAvaliadoCiclico'
import { ModalDelete } from '../../../components/ModalDelete'
import { Paginador } from '../../../components/Paginador'
import { parseJwt } from '../../../services/token'
import { ModalAlerta } from './Components/ModalAlerta'
import { SelectCmp } from '../../../components/SelectCmp'
import { SearchCmp } from '../../../components/SearchCmp'

interface iModel {
  nomePesquisa: string
  dataInicio: string
  dataFim: string
  avaliacoes: iAvaliado[]
  avaliacoesCiclicas: iAvaliadoCiclica[]
  statusPesquisa: string
  encerrada: boolean
  totalAvaliadores: number
  totalAvaliados: number
  ciclo?: string
  diasPDI?: number
  lembretePDI?: boolean
  resultadoGeral: boolean
  quantidade?: number
  quantidadeFiltrada: number
  formularioAprovado?: boolean
  configurada?: boolean
  processoOnboard?: boolean
}

export interface iAvaliadoCiclica {
  principal?: iAvaliado
  avaliacoes: iAvaliado[]
}

interface iColaboradorBase {
  id: string
  nome: string
  avatar: string
  nomeDepartamento: string
  gestorId: string
  gestorNome?: string
  departamentoId: string
}

export interface iAvaliado {
  id: string
  colaborador: iColaboradorBase
  totalAvaliadores: number
  totalRespostas: number
  autoavaliacao?: boolean
  gestor?: boolean
  encerrada: boolean
  iniciada: boolean
  dataInicio?: string
  dataFim?: string
  liberarResultado: number
  interrompida: boolean
  pdi: boolean
  pdiAtrasado?: boolean
  statusCiclica?: iStatusCiclia
  apenasAutoAvaliacao: boolean
}

export interface iStatusCiclia {
  texto?: string
  cor?: string
  data?: string
}

export const ResultadoPesquisaPercepcao: React.FC = () => {
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const modalAlerta = new URLSearchParams(useLocation().search).get('modalAlerta')
  const nav = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()

  const [IsLoading, setIsLoading] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Tamanho, setTamanho] = useState(25)

  const [Model, setModel] = useState<iModel>({
    nomePesquisa: '',
    dataInicio: '',
    dataFim: '',
    totalAvaliados: 0,
    totalAvaliadores: 0,
    avaliacoes: [],
    encerrada: false,
    statusPesquisa: '',
    resultadoGeral: false,
    quantidadeFiltrada: 0,
    avaliacoesCiclicas: []
  })

  const [ModelSemFiltros, setModelSemFiltros] = useState<iModel>({
    nomePesquisa: '',
    dataInicio: '',
    dataFim: '',
    totalAvaliados: 0,
    totalAvaliadores: 0,
    avaliacoes: [],
    encerrada: false,
    statusPesquisa: '',
    resultadoGeral: false,
    quantidadeFiltrada: 0,
    avaliacoesCiclicas: []
  })

  const [EncerrarPesquisaIsOpen, setEncerrarPesquisaIsOpen] = useState(false)
  const [Pesquisa, setPesquisa] = useState('')
  const [StatusPesquisa, setStatusPesquisa] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [DepartamentoPesquisa, setDepartamentoPesquisa] = useState('')
  const [GestorPesquisa, setGestorPesquisa] = useState('')
  const [AvaliadoId, setAvaliadoId] = useState<string>()
  const [IsFilterOn, setIsFilterOn] = useState(false)
  const [Ordenacao, setOrdenacao] = useState('asc')

  const [IsOpenModalAlerta, setIsOpenModalAlerta] = useState(true)
  const [ModalReabrirIsOpen, setModalReabrirIsOpen] = useState(false)
  const [ModalInterromperIsOpen, setModalInterromperIsOpen] = useState(false)
  const [ModalConfiguracaoIsOpen, setModalConfiguracaoIsOpen] = useState(false)

  const [Avaliado, setAvaliado] = useState<iAvaliado>()

  const [UltimaEncerrada, setUltimaEncerrada] = useState<iAvaliado>()

  function getAvaliados(isSearch?: boolean): void {
    setIsFilterOn(!!isSearch)
    const customPath = `isEncerrada=${PropInvalida(Model.ciclo) ? StatusPesquisa : ''}&departamentoId=${DepartamentoPesquisa}&gestorId=${GestorPesquisa}&busca=${Pesquisa}&`
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/Resultado/?${isSearch ? customPath : ''}ordenar=${Ordenacao}&tamanho=${Tamanho}&pagina=${Pagina}&statusCiclica=${!PropInvalida(Model.ciclo) ? StatusPesquisa : ''}`)
      .then(res => {
        if (((res.data.avaliacoesCiclicas && res.data.avaliacoesCiclicas.length > 0) || (res.data.avaliacoes && res.data.avaliacoes.length > 0)) || Pagina === 0) {
          setModel(res.data)
        } else {
          setPagina(0)
        }
        setIsLoading(false)
      })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  function getAvaliadosSemFiltros(): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/Resultado/?tamanho=&pagina=`)
      .then(res => { setModelSemFiltros(res.data); setIsLoading(false) })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  function EncerrarAvaliacao(id: string): void {
    appApi.patch(`AvaliacaoPesquisa/${id}/Encerrar?pesquisaId=${pesquisaId}`)
      .then(() => getAvaliados())
      .catch(err => console.log(err))
  }

  function ReabrirAvaliacao(id: string): void {
    appApi.patch(`AvaliacaoPesquisa/${id}/Reabrir?pesquisaId=${pesquisaId}`)
      .then(() => getAvaliados())
      .catch(err => console.log(err))
  }

  function openConfiguracao(avaliado: iAvaliado): void {
    setAvaliado(avaliado)
    setModalConfiguracaoIsOpen(true)
  }

  function onOpenModalInterromper(id: string): void {
    setAvaliadoId(id)
    setModalInterromperIsOpen(true)
  }

  function onCloseModalInterromper(): void {
    setAvaliadoId(undefined)
    setModalInterromperIsOpen(false)
  }

  function closeConfiguracao(): void {
    setAvaliado(undefined)
    setModalConfiguracaoIsOpen(false)
  }

  function Salvar(dataInicio: string, dataFim: string, reenviar: boolean): void {
    appApi.put(`AvaliacaoPesquisa/${Avaliado?.id ?? ''}/Salvar/Ciclica?dataInicio=${dataInicio}&dataFim=${dataFim}&reenviar=${reenviar ?? ''}`)
      .then(() => {
        toast({
          title: 'Salvo com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        closeConfiguracao()
        getAvaliados()
      })
      .catch(err => console.log(err))
  }

  function EncerrarPesquisa(): void {
    const ColaboradorId = Model.ciclo ? Model.avaliacoesCiclicas?.find(e => e.principal?.id === AvaliadoId)?.principal?.colaborador?.id : Model.avaliacoes?.find(e => e.id === AvaliadoId)?.colaborador?.id
    if (PropInvalida(ColaboradorId)) {
      toast({
        title: 'Erro ao interromper',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
    }

    appApi.patch(`PesquisaPercepcao/${pesquisaId}/Interromper/${ColaboradorId}`)
      .then(e => {
        toast({
          title: 'Encerrada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getAvaliados()
      })
      .catch(err => console.log(err))

    const avaliacoesColaborador = Model.avaliacoes.filter(e => e.colaborador.id === ColaboradorId)
    const avaliacoesOutros = Model.avaliacoes.filter(e => e.colaborador.id !== ColaboradorId)

    if (avaliacoesColaborador.length > 1) {
      avaliacoesColaborador.length = 1
    }

    const avaliacoesCombinadas = avaliacoesColaborador.concat(avaliacoesOutros)

    setModel({ ...Model, avaliacoes: avaliacoesCombinadas })
  }

  function EncerrarPesquisaPercep(): void {
    appApi.patch(`PesquisaPercepcao/${pesquisaId}/Encerrar`)
      .then(e => {
        toast({
          title: 'Pesquisa encerrada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getAvaliados()
      })
      .catch(err => console.log(err))
  }

  function OnOpenModalReabrir(Id: string): void {
    setAvaliadoId(Id)
    setModalReabrirIsOpen(true)
  }

  function OnCloseModalReabrir(): void {
    setAvaliadoId('')
    setModalReabrirIsOpen(false)
  }

  function ReabrirPesquisa(dataFim: string): void {
    appApi.put(`PesquisaPercepcao/${pesquisaId}/Reabrir?dataFim=${dataFim}`)
      .then(() => {
        toast({
          title: 'Pesquisa reaberta com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        getAvaliados()
        ReabrirAvaliacao(AvaliadoId ?? '')
      })
      .catch(err => { console.log(err) })
  }

  function AtualizarLembrete(): void {
    appApi.patch(`PesquisaPercepcao/${pesquisaId}/PDI/Lembrete`)
      .then(() => setModel({ ...Model, lembretePDI: !Model.lembretePDI }))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getAvaliadosSemFiltros()
  }, [])

  useEffect(() => {
    getAvaliados()
  }, [Tamanho])

  useEffect(() => {
    getAvaliados(IsFilterOn)
  }, [Pagina])

  useEffect(() => {
    if (Model.avaliacoes.length >= 2 && Model.avaliacoes?.some(r => r.encerrada)) {
      const encerradas = Model.avaliacoes.filter(e => e.encerrada)
      setUltimaEncerrada(encerradas[encerradas.length - 1])
    }
  }, [Model])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalInterromperIsOpen}
        message={`Deseja mesmo interromper as avaliações do colaborador ${Model.avaliacoes?.find(e => e.id === AvaliadoId)?.colaborador.nome ?? ''}?`}
        onConfirm={EncerrarPesquisa}
        center
        onRequestClose={onCloseModalInterromper}
      />

      <ModalDelete
        isOpen={EncerrarPesquisaIsOpen}
        message='Deseja mesmo encerrar essa pesquisa?'
        onConfirm={EncerrarPesquisaPercep}
        center
        onRequestClose={() => setEncerrarPesquisaIsOpen(false)}
      />

      {!IsLoading && IsOpenModalAlerta && modalAlerta === 'true' && !Model.avaliacoes?.some(e => !e.encerrada) && (
        <ModalAlerta
          isOpen={IsOpenModalAlerta}
          onRequestClose={() => setIsOpenModalAlerta(false)}
        />
      )}

      <ModalConfiguracao
        isOpen={ModalConfiguracaoIsOpen}
        avaliado={Avaliado}
        dataFimUltimoEncerrado={UltimaEncerrada?.dataFim}
        onConfirm={Salvar}
        onRequestClose={closeConfiguracao}
      />

      <ModalReabrir
        isOpen={ModalReabrirIsOpen}
        message='Para reabrir a pesquisa, você precisa alterar a data de término, deseja continuar?'
        onConfirm={(e) => ReabrirPesquisa(e)}
        onRequestClose={OnCloseModalReabrir}
      />

      <Flex mt={'.5rem'} justifyContent={'space-between'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: pathname.includes('Desempenho') ? '/Desempenho/AnaliseDesempenho/Gestao' : '/Jornada/Percepcao/Gestao'
          },
          {
            nome: Model?.nomePesquisa ?? ''
          }
        ]} />
      </Flex>

      <Flex justifyContent={'space-between'}>
        <Flex flexDirection={'column'}>
          <h1>{Model?.nomePesquisa}</h1>
        </Flex>
        <Flex gap={'.5rem'}>
          {!Model.encerrada && parseJwt().adm === 'True' &&
            (<ButtonCmp
              VarColor='Red' leftIcon={<FaRegStopCircle/>} onClick={() => setEncerrarPesquisaIsOpen(true)}
            >
              Encerrar pesquisa
            </ButtonCmp>)
          }

          {
            (Model.processoOnboard !== true && Model.formularioAprovado && !Model.encerrada && parseJwt().adm === 'True') && (
              <ButtonCmp
                VarColor='Green2'
                leftIcon={<FaUsers/>}
                onClick={() => nav(`${pathname.split('/Resultado')[0]}/Participantes/${pesquisaId}/Editar`)}
              >
                Gestão de participantes
              </ButtonCmp>
            )
          }

          {
            (Model.processoOnboard === true && Model.formularioAprovado && parseJwt().adm === 'True') && (
              <ButtonCmp
                VarColor='Green2'
                leftIcon={<FaUsers/>}
                onClick={() => nav(`${pathname.split('/Resultado')[0]}/Participantes/${pesquisaId}/Processo`)}
              >
                Gestão de participantes
              </ButtonCmp>
            )
          }
          {Model.resultadoGeral && (
            <ButtonCmp
              onClick={() => nav(`${pathname.replace('ResultadoPesquisa', 'ResultadoGeral')}`)}
              VarColor='Green2'
            >Relatório geral</ButtonCmp>
          )
          }
          {!PropInvalida(Model.lembretePDI) &&
            <ButtonCmp
              onClick={AtualizarLembrete}
              VarColor={Model.lembretePDI === true ? 'Rosa' : 'Red'}
            >Lembrete de PDI: {Model.lembretePDI === true ? 'Ativado' : 'Desativado'}</ButtonCmp>
          }
        </Flex>
      </Flex>

      <DateText>
        {Model.ciclo && <span>Ciclo: {ModelSemFiltros?.ciclo}</span>}
        {Model.dataInicio && <span>Início: {ModelSemFiltros?.dataInicio}</span>}
        {Model.dataFim && <span>Fim: {ModelSemFiltros?.dataFim}</span>}
        <span>Avaliados: {ModelSemFiltros?.totalAvaliados}</span>
        <span>Total de avaliadores: {ModelSemFiltros?.totalAvaliadores}</span>
      </DateText>

      <WhiteContainer margin='.5rem 0rem 0rem 0rem'>
        <Flex marginBottom={'.75rem'} justifyContent={'space-between'} alignItems={'end'}>
          <Flex gap={'.5rem'}>
            <FormControl width={'30rem'}>
              <SelectCmp title='Ordenação' value={Ordenacao} OnChange={setOrdenacao}>
                <option value={'asc'}>A-Z</option>
                <option value={'desc'}>Z-A</option>
              </SelectCmp>
            </FormControl>
            <FormControl>
              <SelectCmp title='Status' value={StatusPesquisa} OnChange={setStatusPesquisa}>
                <option value={''}>Nenhum</option>
                {PropInvalida(Model.ciclo) &&
                  <>
                    <option value={'true'}>Encerrada</option>
                    <option value={'false'}>Em andamento</option>

                    {/* PRE
                    <option value={'Encerrada'}>Encerrada</option>
                    <option value={'Andamento'}>Em andamento</option> */}
                  </>}
                {!PropInvalida(Model.ciclo) &&
                  <>
                    <option value={'0'}>Aguardando inicio</option>
                    <option value={'1'}>Pesquisa em andamento</option>
                    <option value={'2'}>Pesquisa em atraso</option>
                    <option value={'3'}>Aguardando PDI</option>
                    <option value={'4'}>PDI em atraso</option>
                    <option value={'5'}>PDI cadastrado</option>
                  </>
                }

              </SelectCmp>
            </FormControl>

            <FormControl >
              <SelectCmp title='Área' OnChange={(e) => {
                setDepartamentoPesquisa(e)
                if (!PropInvalida(Model.ciclo)) {
                  setStatusPesquisa('')
                }
              }}>
                <option value={''}>Nenhum</option>
                {ReturnUniqueByKey(ModelSemFiltros.avaliacoes?.map(e => { return e.colaborador }), 'departamentoId', 'nomeDepartamento').map((e, i) => {
                  return (
                    <option key={i} value={e.departamentoId}>{e.nomeDepartamento}</option>
                  )
                })}
                {ReturnUniqueByKey(ModelSemFiltros.avaliacoesCiclicas?.map(e => { return e.principal?.colaborador }), 'departamentoId', 'nomeDepartamento').map((e, i) => {
                  return (
                    <option key={i} value={e.departamentoId}>{e.nomeDepartamento}</option>
                  )
                })}
              </SelectCmp>
            </FormControl>

            <FormControl>
              <SelectCmp title='Gestor' OnChange={(e) => {
                setGestorPesquisa(e)
                if (!PropInvalida(Model.ciclo)) {
                  setStatusPesquisa('')
                }
              }}>
                <option value={''}>Nenhum</option>
                {ReturnUniqueByKey(ModelSemFiltros.avaliacoesCiclicas.map(e => { return e.principal?.colaborador }), 'gestorId', 'gestorNome').map((e, i) => {
                  if (e.gestorNome !== '') {
                    return (
                      <option key={i} value={e.gestorId}>{e.gestorNome}</option>
                    )
                  }
                })}
              </SelectCmp>
            </FormControl>

          </Flex>
          <Flex w='100%' justifyContent='end' gap={'.5rem'} alignItems='center'>
            <SearchCmp
              width={'20rem'}
              placeholder='Pesquisa'
              OnChange={setPesquisa}
              EnterAction={() => getAvaliados(true)}
            />
            <Flex justifyContent='end' alignItems='center'>
              <ButtonCmp VarColor='Green2' rightIcon={<FaFilter />} onClick={() => { getAvaliados(true) }}>Filtrar</ButtonCmp>
            </Flex>
          </Flex>
        </Flex>

        {Model.avaliacoes?.some(e => e.encerrada) && <Alert borderRadius={'.25rem'} justifyContent='space-between' status='warning' px={'.75rem'} py={'.325rem'}>
          <Flex flexDir={'column'} fontSize={'.75rem'}>
            <Flex>
                <AlertIcon color={'#FF9900'} />
                <AlertTitle>IMPORTANTE: </AlertTitle>
                <AlertDescription>Os convites de preenchimento são enviados apenas para as avaliações em andamento.</AlertDescription>
            </Flex>
          </Flex>
        </Alert>}

        <h3 style={{ margin: '.5rem 0' }}>Avaliados</h3>
        {
          (Model && Model.avaliacoes.length > 0 && PropInvalida(Model.ciclo)) && (
            <Paginador
              next={(e) => setPagina(e)}
              prev={(e) => setPagina(e)}
              tamanho={Tamanho}
              setTamanho={(e) => setTamanho(e)}
              paginaAtual={Pagina}
              qtdElementos={Model.quantidadeFiltrada}
              hideTop={true}
            >
              <Flex gap={'.5rem'} flexDirection={'column'}>
                {
                  Model?.avaliacoes.map((e) => {
                    return (
                      <CardAvaliado
                        onEncerrar={EncerrarAvaliacao}
                        onReabrir={ReabrirAvaliacao}
                        key={e.id}
                        avaliado={e}
                        onOpenModalReabrir={OnOpenModalReabrir}
                        pesquisaEncerrada={Model.encerrada}
                      />
                    )
                  })
                }
              </Flex>
            </Paginador>
          )
        }

        {
          (Model && Model.avaliacoesCiclicas.length > 0 && !PropInvalida(Model.ciclo)) && (
            <Flex gap={'1rem'} flexDirection={'column'}>
              <Paginador
                next={(e) => setPagina(e)}
                prev={(e) => setPagina(e)}
                tamanho={Tamanho}
                setTamanho={(e) => setTamanho(e)}
                paginaAtual={Pagina}
                qtdElementos={Model.totalAvaliados}
                hideTop={true}
              >
                {
                  Model.avaliacoesCiclicas.map((e, i) => {
                    return (
                      <CardAvaliadoCiclico
                        key={e.principal?.id}
                        onEncerrar={EncerrarAvaliacao}
                        onReabrir={ReabrirAvaliacao}
                        openConfiguracao={openConfiguracao}
                        onOpenModalReabrir={OnOpenModalReabrir}
                        onOpenModalInterromper={onOpenModalInterromper}
                        avaliacao={e}
                        pesquisaEncerrada={Model.encerrada}
                      />
                    )
                  })
                }
              </Paginador>
            </Flex>
          )
        }

        {
          (Model && Model.avaliacoes?.length === 0 && Model.avaliacoesCiclicas?.length === 0) && (
            <Flex justifyContent={'center'} padding='1rem'>
              <h1>Selecione um participante para esta pesquisa</h1>
            </Flex>
          )
        }
      </WhiteContainer>
    </Body>
  )
}
