import styled from 'styled-components'

export const ModalBody = styled.div`
display: flex;
flex-direction: column;

.VariantColor{
    padding: .5rem;
    background: var(--Azul);
    color: var(--c1);
    border: 1px solid var(--c6)
}

a:first-child{
    margin-right:.5rem;
}

overflow: auto;
max-height:60vh;
::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
section{
  overflow-x: hidden;
}
`

export const ImgVariant = styled.img`
 height: 2.5rem;
 width: 2.5rem;
 border-radius: 50%;
 background: var(--c1);
 margin-right: .5rem;
`

export const ModalFooter = styled.div`
display: flex;
justify-content: end;
margin-top: .75rem;
padding-right: 1rem;
padding-bottom: .75rem;
`
