import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { iCompetencia, iModalPadrao } from '../../../../../interfaces'
import { ModalBody, ModalFooter, ImgVariant } from './styles'
import { ModalBase } from '../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../components/SelectCmp'
import { appApi } from '../../../../../services/appApi'

interface iProps extends iModalPadrao {
  onConfirm: (competenciaId: string[]) => void
  idsIgnorar: string[]
}

export const ModalTodasCompetencias: React.FC<iProps> = ({ OnCloseModal, isOpen, onConfirm, idsIgnorar }) => {
  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [CompetenciaId, setCompetenciaId] = useState<string[]>([])
  const [Competencias, setCompetencias] = useState<iCompetencia[]>([])

  function orderByName(isDesc: boolean): void {
    const arr = [...Competencias]
    arr.sort((a, b) => {
      return a.nome.localeCompare(b.nome, 'pt-BR')
    })
    if (isDesc) arr.reverse()
    setCompetencias(arr)
  }

  function getCompetencias(): void {
    appApi.get('Competencia')
      .then(response => setCompetencias(response.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getCompetencias()
  }, [])

  return (
    <ModalBase
      isOpen={isOpen as boolean}
      Titulo='Competências'
      onClose={OnCloseModal}
      Width='75rem'
    >

      <Flex justifyContent={'space-between'} alignItems='end' mx={'.5rem'} mb={'.75rem'} mt={'0'}>
        <SelectCmp
          width={'18rem'}
          title='Ordenar por'
          OnChange={(e) => {
            if (e === '0') {
              orderByName(false)
            } else if (e === '1') {
              orderByName(true)
            }
          }}
        >
          <option value={'0'}>A-Z</option>
          <option value={'1'}>Z-A</option>
        </SelectCmp>

        <Flex>
          <InputCmp
            position={'inherit'}
            value={TxtPesquisa}
            OnChange={setTxtPesquisa}
            placeholder='Buscar Competências'
            width={'17rem'}
          />
        </Flex>
      </Flex>

      <ModalBody>
        <section>
          {
            Competencias?.filter(e => !e.discursiva).filter((e: iCompetencia) => {
              if (idsIgnorar.includes(e.id)) {
                return null
              }
              if (TxtPesquisa === '') {
                return e
              } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                return e
              }
              return null
            }).map((e: iCompetencia, i) => {
              return (
                <Flex cursor={'pointer'} _hover={{ filter: 'brightness(0.95)', boxShadow: 'var(--SombraBtns)' }} key={i} className='VariantColor' justifyContent={'space-between'} onClick={() => { if (CompetenciaId.some(c => c === e.id)) { setCompetenciaId(CompetenciaId.filter(c => c !== e.id)) } else { setCompetenciaId([...CompetenciaId, e.id]) } }}>
                  <Flex>
                    <ImgVariant borderColor={e.doSistema ? 'Azul' : 'AzulOpaco'} src={e.icone} />
                    <Flex flexDirection={'column'}>
                      <h4>{e.nome}</h4>
                      <small>{e.doSistema ? 'Padrão' : e.dataCriacao}</small>
                    </Flex>
                  </Flex>

                  <Flex alignItems={'center'} gap={'.5rem'}>
                    {
                      CompetenciaId.some(c => c === e.id)
                        ? <ButtonCmp w={'5.5rem'} VarColor='Green2'>Selecionado</ButtonCmp>
                        : <ButtonCmp w={'5.5rem'} OutlineColor='Green2'>Selecionar</ButtonCmp>
                    }
                  </Flex>
                </Flex>
              )
            })
          }
        </section>
      </ModalBody>
      <ModalFooter>
        <ButtonCmp VarColor='c6' mr={'.5rem'} onClick={() => OnCloseModal()}>Voltar</ButtonCmp>
        <ButtonCmp isDisabled={!CompetenciaId || CompetenciaId.length === 0} VarColor='Green2' onClick={() => { onConfirm(CompetenciaId); setCompetenciaId([]) }}>Adicionar</ButtonCmp>
      </ModalFooter>
    </ModalBase>
  )
}
