/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable multiline-ternary */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, AvatarGroup, Box, CircularProgress, CircularProgressLabel, Divider, Flex, FormControl, Grid, HStack, Text, toast, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineTable } from 'react-icons/ai'
import { BsGraphUp } from 'react-icons/bs'
import { FaArrowLeft, FaArrowRight, FaCheck, FaClock, FaFileCsv, FaFilter, FaPauseCircle, FaPencilAlt, FaPlus, FaSpinner, FaStopwatch, FaTrash } from 'react-icons/fa'
import { IoMdCloseCircle } from 'react-icons/io'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { MdOutlineTaskAlt } from 'react-icons/md'
import { TbReportAnalytics } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { AccordionContainer } from '../../../components/AccordionContainer'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import LoadingContainer from '../../../components/LoadingContainer'
import { ModalDelete } from '../../../components/ModalDelete'
import { SearchCmp } from '../../../components/SearchCmp'
import { SelectCmp } from '../../../components/SelectCmp'
import { SwitchCmp } from '../../../components/SwitchCmp'
import { iColaboradorBase, iNomeId } from '../../../interfaces'
import { appApi, urlApi } from '../../../services/appApi'
import { getToken } from '../../../services/token'
import { UpdateElementByPosition } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'
import { IndicadorChart } from './components/IndicadorChart'
import { IndicadorVariosPeriodosChart } from './components/IndicadorVariosPeriodosChart'
import { ModalCriterios } from './components/ModalCriterios'
import { iIndicadorSelecionado, ModalGerarComparativo } from './components/ModalGerarComparativo'
import { iIndicadorInput, ModalIndicador } from './components/ModalIndicador'
import { ModalNineBox } from './components/ModalNineBox'
import { ModalSubirEmMassa } from './components/ModalSubirEmMassa'
import { ModalVisualizarParticipantes } from './components/ModalVisualizarParticipantes'
import { Tabela } from './components/Tabela'

export interface iIndicador {
  id: string
  nome: string
  grupoIndicadoresId?: string
  criterios: iCriterios
  meta: number
  dataInicio: string
  tipoMeta: number
  medidaMeta: string
  prazo: string
  periodos: iPeriodo[]
  periodosADM?: iPeriodo[]
  periodosSugestao?: iPeriodo[]
  status: number
  participantes?: iColaboradorBase[]
  responsavel?: iColaboradorBase
  isResponsavel: boolean
  metaInversa?: boolean
  jaAvaliadosNineBox?: string[]
}

export interface iCriterios {
  criterios: string
  textoExibicao: string
  meta: number
  progressoTotal: number
  periodos: iPeriodo[]
  colaboradores: iNomeId[]
  graficos: iGraficoCriterios[]
}

export interface iGraficoCriterios {
  titulo: string
  tipoGrafico: number
  labels: string[]
  data: number[][]
}

export interface iPeriodo {
  id: string
  indicadorId: string
  ano: number
  meses: iMesPeriodo[]
  status: number
  tabelaADM?: boolean
}

export interface iMesPeriodo {
  id: string
  periodoId: string
  numeroMes: number
  real?: number
  previsto?: number
  statusReal: number
  statusPrevisto: number
}

interface iMesAprovado {
  real?: boolean
  previsto?: boolean
  id: string
}

interface iPeriodoFiltro {
  id: string
  periodoInicial?: number
  periodoFinal?: number
}

export const Indicadores: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const mesesAbreviados = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]
  const [Indicadores, setIndicadores] = useState<iIndicador[]>([])
  const [Indicador9Box, setIndicador9Box] = useState<iIndicador>()
  const [ComparativoChart, setComparativoChart] = useState<iIndicador[]>([])
  const [IsLoadingId, setIsLoadingId] = useState<string>()
  const [IsLoadingAll, setIsLoadingAll] = useState(false)
  const [ModalCriarIsOpen, setModalCriarIsOpen] = useState(false)
  const [GerarComparativoIsOpen, setGerarComparativoIsOpen] = useState(false)
  const [PodeCriar, setPodeCriar] = useState(false)
  const [IsModerador, setIsModerador] = useState(false)
  const [VerificarMudanca, setVerificarMudanca] = useState<iIndicador>()
  const [MesesAprovados, setMesesAprovados] = useState<iMesAprovado[]>([])
  const [PeriodoSelecionado, setPeriodoSelecionado] = useState<Array<{ id: string, val: number }>>([])
  const [VisualizarParticipantes, setVisualizarParticipantes] = useState<string>()
  const [FilterStatus, setFilterStatus] = useState('')
  const [SearchText, setSearchText] = useState('')
  const [DeleteId, setDeleteId] = useState<string>()
  const [EditId, setEditId] = useState<string>()
  const [ConcluirId, setConcluirId] = useState<string>()
  const [isLoadingFiltro, setisLoadingFiltro] = useState<string>()
  const [ModalSubirEmMassaIsOpen, setModalSubirEmMassaIsOpen] = useState<string>()
  const [ModalCristeriosIsOpen, setModalCristeriosIsOpen] = useState<string>()
  const [PeriodosFiltro, setPeriodosFiltro] = useState<iPeriodoFiltro[]>([])
  const [TabelaAdm, setTabelaAdm] = useState(false)

  function formatarDataAno(dataString: string): string {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ]

    const [ano, mes] = dataString.split('-')

    const nomeMes = meses[parseInt(mes) - 1]

    return `${nomeMes} de ${ano}`
  }

  function GetIndicadores(id?: string): void {
    setIsLoadingId(id)
    appApi.get('Indicador')
      .then(res => setIndicadores(res.data))
      .catch(err => console.log(err))
      .finally(() => { setTimeout(() => { setIsLoadingId(undefined); setIsLoadingAll(false) }, 500) })
  }

  function GetPodeCriar(): void {
    appApi.get('Indicador/PodeCriar')
      .then(res => setPodeCriar(res.data))
      .catch(err => console.log(err))
      .finally(() => { setTimeout(() => { setIsLoadingId(undefined) }, 500) })
  }

  function GetIsModerador(): void {
    appApi.get('Indicador/IsModerador')
      .then(res => setIsModerador(res.data))
      .catch(err => console.log(err))
      .finally(() => { setTimeout(() => { setIsLoadingId(undefined) }, 500) })
  }

  function OnCreate(input: iIndicadorInput): void {
    setIsLoadingAll(true)
    appApi.post('Indicador', input)
      .then((res) => { GetIndicadores(res.data); setModalCriarIsOpen(false) })
      .catch((err) => console.log(err))
  }

  function GetValorAcumulado(periodos: iPeriodo[], tipoMeta: number, meta?: number, metaInversa?: boolean): number {
    let response = 0
    if (tipoMeta === 5 && periodos.length > 0) {
      const meses = periodos?.sort((a, b) => b.ano - a.ano)[0]?.meses?.filter(e => e.real)?.sort((a, b) => b.numeroMes - a.numeroMes)
      if (meses.length > 0) {
        response = meses[0].real ?? 0
      }
    } else {
      periodos.forEach(e => e.meses.forEach(m => {
        if (m.real) {
          response += m.real
        }
      }))
    }

    if (meta) {
      return (metaInversa ? response === 0 ? 0 : (meta / response) : (response / meta)) * 100
    } else {
      return response
    }
  }

  function GetValorPrevisto(periodos: iPeriodo[]): number {
    let mesAtual = new Date().getUTCMonth() + 1
    let anoAtual = new Date().getUTCFullYear()
    let periodo = periodos.find(e => e.ano === anoAtual)
    while (!periodo) {
      if (anoAtual < 2000) {
        break
      }
      anoAtual--
      periodo = periodos.find(e => e.ano === anoAtual)
    }

    let mes = periodo?.meses.find(e => e.numeroMes === mesAtual)
    while (mes === null || mes?.previsto === 0 || mes?.previsto === null) {
      if (mesAtual < 0) {
        break
      }
      mesAtual--
      mes = periodo?.meses.find(e => e.numeroMes === mesAtual)
    }

    return mes?.previsto ?? 0
  }

  function GetValorAcumuladoAnterior(indicadorId: string, periodos: iPeriodo[]): number {
    let response = 0
    periodos.forEach((e, i) => e.meses.forEach(m => {
      if (m.real && i < (PeriodoSelecionado.find(p => p.id === indicadorId)?.val ?? 0)) {
        response += m.real
      }
    }))

    return response
  }

  function PrevPage(idPeriodo: string): void {
    let periodo = PeriodoSelecionado.find(e => e.id === idPeriodo)
    const index = PeriodoSelecionado.findIndex(e => e.id === idPeriodo)

    if (!periodo) {
      periodo = { val: 0, id: idPeriodo }
    }
    const periodoSelecionado = periodo?.val
    if (periodoSelecionado !== 0) {
      periodo.val = periodoSelecionado - 1
    } else {
      periodo.val = (Indicadores.find(e => e.id === idPeriodo)?.periodos.length ?? 0) - 1 ?? 0
    }
    if (index !== -1) {
      setPeriodoSelecionado(UpdateElementByPosition(PeriodoSelecionado, index, periodo))
    } else {
      setPeriodoSelecionado([...PeriodoSelecionado, periodo])
    }
  }

  function NextPage(idPeriodo: string): void {
    let periodo = PeriodoSelecionado.find(e => e.id === idPeriodo)
    const index = PeriodoSelecionado.findIndex(e => e.id === idPeriodo)

    if (!periodo) {
      periodo = { val: 0, id: idPeriodo }
    }
    const periodoSelecionado = periodo?.val
    if (periodoSelecionado !== (Indicadores.find(e => e.id === idPeriodo)?.periodos.length ?? 0) - 1) {
      periodo.val = periodoSelecionado + 1
    } else {
      periodo.val = 0
    }
    if (index !== -1) {
      setPeriodoSelecionado(UpdateElementByPosition(PeriodoSelecionado, index, periodo))
    } else {
      setPeriodoSelecionado([...PeriodoSelecionado, periodo])
    }
  }

  function OnChangeMesAprovado(add: boolean, id: string, campo: 'real' | 'previsto'): void {
    if (add) {
      let item = MesesAprovados.find(e => e.id === id)
      if (item === undefined) {
        item = { id: id, [campo]: true }
        setMesesAprovados([...MesesAprovados, item])
      } else {
        item[campo] = true
        const index = MesesAprovados.findIndex(e => e.id === id)
        setMesesAprovados(UpdateElementByPosition(MesesAprovados, index, item))
      }
    } else {
      const item = MesesAprovados.find(e => e.id === id)
      if (item) {
        item[campo] = false
        const index = MesesAprovados.findIndex(e => e.id === id)
        setMesesAprovados(UpdateElementByPosition(MesesAprovados, index, item))
      }
    }
  }

  function AprovarAlteracoes(indicadorId: string, ano: number): void {
    const copy = Indicadores.find(e => e.id === indicadorId)
    const periodo = copy?.periodos.find(e => e.ano === ano)
    const periodosSugestao = copy?.periodosSugestao?.find(e => e.ano === ano)
    if (!periodo || !periodosSugestao) return
    periodo?.meses.forEach(e => {
      const mesAlterado = MesesAprovados.find(m => m.id === e.id)
      const mesSugestao = periodosSugestao?.meses?.find(m => m.numeroMes === e.numeroMes)
      if (!mesAlterado || !mesSugestao) return

      if (mesAlterado?.previsto) {
        e.previsto = mesSugestao?.previsto
      }
      if (mesAlterado?.real) {
        e.real = mesSugestao?.real
      }
    })
    appApi.patch(`Indicador/${periodo?.id}/Periodo`, { meses: periodo?.meses, periodoSugestaoId: periodosSugestao.id })
      .then(res => setTimeout(() => { GetIndicadores(); setVerificarMudanca(undefined) }, 500))
      .catch((err) => console.log(err))
  }

  function FormatarNumero(num: number, indicador: iIndicador): string {
    let response = ''
    const toFixed = indicador.tipoMeta === 5 || indicador.tipoMeta === 0

    const numero = toFixed ? num.toFixed(2) : num.toString()

    const values = numero.split('.')

    for (let i = 0; i < (values[0].length / 3); i++) {
      for (let index = 1; index < 4; index++) {
        if (values[0][values[0].length - index - (i * 3)]) {
          response = values[0][values[0].length - index - (i * 3)] + response
        }
      }
      if ((i + 1) < (values[0].length / 3)) {
        response = '.' + response
      }
    }

    if (toFixed) {
      response += `,${values[1]}`
    }
    return response
  }

  function onDeleteIndicador(): void {
    appApi.delete(`Indicador/${DeleteId}`)
      .then((res) => {
        GetIndicadores()
        toast({
          title: 'Indicador excluído com sucesso',
          position: 'top',
          status: 'success',
          duration: 4000
        })
      })
      .catch((err) => console.log(err))
  }

  function onConcluirIndicador(): void {
    appApi.patch(`Indicador/${ConcluirId}/Concluir`)
      .then((res) => {
        GetIndicadores()
        toast({
          title: 'Indicador mandado para aprovação de conclusão com sucesso',
          position: 'top',
          status: 'success',
          duration: 4000
        })
      })
      .catch((err) => console.log(err))
  }

  function FilterIndicadores(): iIndicador[] {
    const response: iIndicador[] = []
    Indicadores.forEach(e => {
      if (FilterStatus !== '') {
        if (e.status !== parseInt(FilterStatus)) {
          return
        }
      }
      if (SearchText !== '') {
        if (!e.nome.toLowerCase().includes(SearchText.toLowerCase())) {
          return
        }
      }

      response.push(e)
    })

    return response
  }

  function OnChangeInicial(id: string, val: number): void {
    setisLoadingFiltro(id)
    let periodo = PeriodosFiltro.find(e => e.id === id)
    if (periodo) {
      periodo.periodoInicial = val
      const index = PeriodosFiltro.findIndex(e => e.id === id)
      setPeriodosFiltro(UpdateElementByPosition(PeriodosFiltro, index, periodo))
    } else {
      periodo = { id: id, periodoInicial: val }
      setPeriodosFiltro([...PeriodosFiltro, periodo])
    }
    setTimeout(() => {
      setisLoadingFiltro(undefined)
    }, 1200)
  }

  function OnChangeFinal(id: string, val: number): void {
    setisLoadingFiltro(id)
    let periodo = PeriodosFiltro.find(e => e.id === id)
    if (periodo) {
      periodo.periodoFinal = val
      const index = PeriodosFiltro.findIndex(e => e.id === id)
      setPeriodosFiltro(UpdateElementByPosition(PeriodosFiltro, index, periodo))
    } else {
      periodo = { id: id, periodoFinal: val }
      setPeriodosFiltro([...PeriodosFiltro, periodo])
    }
    setTimeout(() => {
      setisLoadingFiltro(undefined)
    }, 1200)
  }

  function GerarComparativo(response: iIndicadorSelecionado[]): void {
    setisLoadingFiltro(EditId)
    appApi.post('Indicador/Comparativo', { indicadorId: EditId, periodosId: response })
      .then(res => {
        const data: iIndicador = res.data
        const index = ComparativoChart.findIndex(e => e.id === data.id)
        if (index !== -1) {
          setComparativoChart(UpdateElementByPosition(ComparativoChart, index, data))
        } else {
          setComparativoChart([...ComparativoChart, data])
        }
      })
      .catch((err) => console.log(err))
      .finally(() => OnCloseModalComparativo())
  }

  function OnCloseModalComparativo(): void {
    setEditId(undefined)
    setisLoadingFiltro(undefined)
    setGerarComparativoIsOpen(false)
  }

  useEffect(() => {
    GetIndicadores()
    GetPodeCriar()
    GetIsModerador()
  }, [])

  return (
    <Body>
      <LoadingContainer linhas={24} loading={IsLoadingAll}>
        <ModalIndicador
          isOpen={ModalCriarIsOpen}
          onClose={() => { setModalCriarIsOpen(false); setEditId(undefined) }}
          onUpdate={() => {
            setModalCriarIsOpen(false)
            setEditId(undefined)
            toast({
              title: 'Indicador atualizado com sucesso',
              duration: 4000,
              status: 'success',
              position: 'top'
            })
            GetIndicadores()
          }}
          onConfirm={OnCreate}
          indicadorEdit={Indicadores.find(e => e.id === EditId)}
        />
        {Indicador9Box && <ModalNineBox
          indicador={Indicador9Box}
          isOpen={!!Indicador9Box}
          onClose={() => setIndicador9Box(undefined)}
          onConfirm={() => setIndicador9Box(undefined)}
        />}
        {EditId && <ModalGerarComparativo
          id={EditId}
          indicadores={Indicadores.filter(ind => Indicadores.find(z => z.id === EditId)?.tipoMeta === ind?.tipoMeta && Indicadores.find(z => z.id === EditId)?.medidaMeta === ind?.medidaMeta)}
          isOpen={GerarComparativoIsOpen}
          onConfirm={(response) => GerarComparativo(response)}
          onRequestClose={OnCloseModalComparativo}
        />}
        {Indicadores.find(e => e.id === VisualizarParticipantes) && <ModalVisualizarParticipantes
          isOpen={!!VisualizarParticipantes}
          onClose={() => setVisualizarParticipantes(undefined)}
          onUpdate={() => GetIndicadores()}
          indicadorId={VisualizarParticipantes ?? ''}
          status={Indicadores.find(e => e.id === VisualizarParticipantes)?.status}
          responsavel={Indicadores.find(e => e.id === VisualizarParticipantes)?.responsavel}
          participantes={Indicadores.find(e => e.id === VisualizarParticipantes)?.participantes}
          isResponsavel={Indicadores.find(e => e.id === VisualizarParticipantes)?.isResponsavel ?? false}
        />}
        <ModalDelete
          isOpen={!!DeleteId}
          message='Deseja mesmo excluir esse indicador?'
          onConfirm={onDeleteIndicador}
          onRequestClose={() => setDeleteId(undefined)}
          center
        />
        {ModalCristeriosIsOpen && <ModalCriterios
          id={ModalCristeriosIsOpen}
          isOpen
          onConfirm={GetIndicadores}
          onRequestClose={() => setModalCristeriosIsOpen(undefined)}
          medidaMeta={Indicadores.find(e => e.id === ModalCristeriosIsOpen)?.medidaMeta ?? ''}
          tipoMeta={Indicadores.find(e => e.id === ModalCristeriosIsOpen)?.tipoMeta ?? 0}
          criterios={Indicadores.find(e => e.id === ModalCristeriosIsOpen)?.criterios}
        />}
        <ModalDelete
          isOpen={!!ConcluirId}
          message='Ao confirmar, esse indicador será enviado para análise aos moderadores da empresa, e não poderá ser editado mais. Tem certeza que deseja concluí-lo?'
          onConfirm={onConcluirIndicador}
          onRequestClose={() => setConcluirId(undefined)}
          center
        />
        {ModalSubirEmMassaIsOpen &&
        <ModalSubirEmMassa
          reload={() => GetIndicadores()}
          isOpen
          tabelaADM={TabelaAdm}
          id={ModalSubirEmMassaIsOpen}
          onRequestClose={() => setModalSubirEmMassaIsOpen(undefined)}
        />
      }
        <Flex justifyContent={'space-between'}>
          <h1>Indicadores</h1>
          <Flex gap={'.75rem'}>
            {IsModerador && <ButtonCmp leftIcon={<TbReportAnalytics/>} VarColor='Rosa' onClick={() => nav('Adm')}>Aprovar indicadores pendentes</ButtonCmp>}
            {PodeCriar && <ButtonCmp leftIcon={<FaPlus/>} VarColor='Green2' onClick={() => setModalCriarIsOpen(true)}>Criar indicador</ButtonCmp>}
          </Flex>
        </Flex>
        <Flex justifyContent={'space-between'} alignItems='end' mt={'1rem'}>
          <Flex width={'50%'} gap={'.5rem'}>
            <FormControl width={'16rem'}>
              <SelectCmp OnChange={setFilterStatus} title='Tipo de status' value={FilterStatus}>
                <Box as={'option'} value={''}>Todos os status</Box>
                <Box as={'option'} value={'1'}>Em andamento</Box>
                <Box as={'option'} value={'3'}>Aguardando alteração</Box>
                <Box as={'option'} value={'4'}>Concluído</Box>
                <Box as={'option'} value={'5'}>Atrasados</Box>
              </SelectCmp>
            </FormControl>
          </Flex>
          <Flex gap={'.5rem'} justifyContent={'end'}>
            <SearchCmp
              EnterAction={() => {}}
              OnChange={setSearchText}
              placeholder={'Buscar por nome'}
            />
          </Flex>
        </Flex>
        {FilterIndicadores().length === 0 && <Flex fontWeight={700} py={'5rem'} alignItems={'center'} justifyContent={'center'}>
            Ainda não há nenhum indicador criado!
          </Flex>}
        {FilterIndicadores().length > 0 && <Grid gridTemplateColumns={'repeat(1, 1fr)'} fontWeight={700} gap={'1rem'} my={'1rem'}>
          {FilterIndicadores().map((e, index) => {
            const ValorPrevisto = GetValorPrevisto(e.periodos)
            const ValorAcumulado = GetValorAcumulado(e.periodos, e.tipoMeta)
            const ProgressoMeta = GetValorAcumulado(e.periodos, e.tipoMeta, e.meta, e.metaInversa)
            return (
              <Flex flexDir={'column'} p={'1.25rem'} pb={e.periodos.length > 0 ? '0' : ''} borderRadius={'.25rem'} border={'1px solid var(--c5)'} boxShadow={'var(--SombraBackground)'} bg={'var(--c1)'}>
                <Flex justifyContent={'space-between'}>
                  {e.periodos.length > 0 && <Flex gap={'1rem'}>
                    {(IsModerador || e.isResponsavel) && <ButtonCmp small onClick={() => setModalCristeriosIsOpen(e.id)} leftIcon={<MdOutlineTaskAlt/>} VarColor='Green2'>Critérios</ButtonCmp>}
                  </Flex>}
                  <div></div>
                  <Flex justifyContent={'end'} gap={'1.25rem'} mb={'.75rem'}>
                    {e.status === 2 && <Flex justifyContent={'end'} gap='.75rem' alignItems={'center'} color={'var(--Yellow)'} fontSize={'14px'}>
                      <FaPauseCircle size={22} /> Aguardando aprovação para concluir
                    </Flex>}
                    {e.status === 3 && <Flex justifyContent={'end'} gap='.75rem' alignItems={'center'} color={'var(--Red)'} fontSize={'14px'}>
                      <Flex justifyContent={'end'} gap='.75rem' alignItems={'center'} fontSize={'14px'}>
                        <FaPauseCircle size={22} /> Aguardando alteração dos participantes do indicador
                      </Flex>
                      {e.isResponsavel && <ButtonCmp small VarColor='Green2' onClick={() => setConcluirId(e.id)}>Concluir indicador</ButtonCmp>}
                    </Flex>}
                    {e.status === 4 && <Flex justifyContent={'end'} gap='1.25rem' alignItems={'center'} color={'var(--Green2)'} fontSize={'14px'}>
                      <Flex gap={'.75rem'} alignItems={'center'}>
                        <FaCheck size={22} /> Concluído
                      </Flex>
                      {((e.participantes && e.participantes.length > 0) || e.responsavel) && e.isResponsavel && <ButtonCmp small VarColor='Rosa' leftIcon={<AiOutlineTable/>} onClick={() => setIndicador9Box(e)}>Avaliar 9Box</ButtonCmp>}
                    </Flex>}
                    {e.status === 5 && <Flex alignItems={'center'} gap='1rem'>
                          <Flex justifyContent={'end'} gap='.75rem' alignItems={'center'} color={'var(--Orange)'} fontSize={'14px'}>
                            <FaClock size={22} /> Atrasado
                          </Flex>
                          {e.isResponsavel && <ButtonCmp small VarColor='Green2' onClick={() => setConcluirId(e.id)}>Concluir indicador</ButtonCmp>}
                        </Flex>}
                    {((e.status === 1 || e.status === 3) && e.periodosSugestao && e.periodosSugestao?.length > 0)
                      ? <Flex justifyContent={'end'} gap='.75rem' alignItems={'center'}>
                      {e.status !== 3 && <Text color={'var(--Rosa)'} fontSize={'14px'}><FaStopwatch/> Aguardando aprovação</Text>}
                      {(VerificarMudanca?.id === e.id ? <ButtonCmp small VarColor='c6' onClick={() => setVerificarMudanca(undefined)}>Voltar</ButtonCmp> : <ButtonCmp small VarColor='Rosa' onClick={() => setVerificarMudanca(e)}>Verificar sugestões de mudança</ButtonCmp>)}
                    </Flex>
                      : e.status === 1
                        ? e.status === 1 && <Flex alignItems={'center'} gap='1rem'>
                          <Flex justifyContent={'end'} gap='.75rem' alignItems={'center'} color={'var(--Azul)'} fontSize={'14px'}>
                            <FaSpinner size={22} /> Em andamento
                          </Flex>
                          {e.isResponsavel && <ButtonCmp small VarColor='Green2' onClick={() => setConcluirId(e.id)}>Concluir indicador</ButtonCmp>}
                        </Flex>
                        : <></>}
                    {(e.status === 1 || e.status === 3 || e.status === 5) && e.isResponsavel && <Flex alignItems={'center'} gap='.5rem' fontSize={'14px'}>
                      <ElementoTooltipCmp label='Editar' background={'var(--Rosa)'}>
                        <FaPencilAlt cursor={'pointer'} color='var(--Rosa)' onClick={() => { setEditId(e.id); setModalCriarIsOpen(true) }}/>
                      </ElementoTooltipCmp>
                      <ElementoTooltipCmp label='Excluir' background={'var(--Red)'}>
                        <FaTrash cursor={'pointer'} color='var(--Red)' onClick={() => setDeleteId(e.id)}/>
                      </ElementoTooltipCmp>
                    </Flex>}
                  </Flex>
                </Flex>
                {(!VerificarMudanca || VerificarMudanca.id !== e.id) && <>
                  <Flex gap={'2rem'}>
                    <Flex flexDir={'column'} flex={1}>
                      <Text fontSize={'18px'}>Nome: <strong style={{ fontWeight: 900 }}>{e.nome}</strong></Text>
                      <Text fontSize={'14px'}>Data de início: {e.dataInicio ? formatarDataAno(e.dataInicio.slice(0, 7)) : 'Indefinido'}</Text>
                      <Text fontSize={'14px'}>Prazo de conclusão: {e.prazo ? formatarDataAno(e.prazo.slice(0, 7)) : 'Indefinido'}</Text>
                    </Flex>
                    {e.tipoMeta !== 5 && <Flex gap={'1rem'} alignItems={'center'} w={'35%'} justifyContent='end'>
                      <Flex flexDir={'column'}>
                        <Text fontSize={'14px'}>Valor previsto até esse mês: <strong>{ValorPrevisto === 0 ? 'N/A' : (e.tipoMeta === 0 ? `${e.medidaMeta ?? ''}${FormatarNumero(ValorPrevisto, e)}` : `${FormatarNumero(ValorPrevisto, e)}${e.medidaMeta ?? ''}`)}</strong></Text>
                        <Text fontSize={'14px'}>Valor acumulado: <strong>{ValorAcumulado === 0 ? 'N/A' : (e.tipoMeta === 0 ? `${e.medidaMeta ?? ''}${FormatarNumero(ValorAcumulado, e)}` : `${FormatarNumero(ValorAcumulado, e)}${e.medidaMeta ?? ''}`)}</strong></Text>
                      </Flex>
                      <Flex flexDir={'column'}>
                        <CircularProgress value={ValorPrevisto === 0 ? 0 : (e.metaInversa ? (ValorPrevisto / ValorAcumulado) : (ValorAcumulado / ValorPrevisto)) * 100} color={e.metaInversa ? ((ValorPrevisto / ValorAcumulado) * 100) < 100 ? 'red.400' : 'green.400' : 'green.400'} size={'60px'} height={'70px'}>
                          <CircularProgressLabel mt={'.325rem'} fontSize={'12px'}>{ValorPrevisto === 0 ? 'N/A' : `${((e.metaInversa ? (ValorPrevisto / ValorAcumulado) : (ValorAcumulado / ValorPrevisto)) * 100).toFixed(1)}%`}</CircularProgressLabel>
                        </CircularProgress>
                      </Flex>
                    </Flex>}
                    <Flex gap={'1rem'} alignItems={'center'} w={'30%'} justifyContent='end'>
                      <Flex flexDir={'column'}>
                        <Text fontSize={'14px'}>Meta final: <strong>{e.tipoMeta === 0 ? `${e.medidaMeta ?? ''}${FormatarNumero(e.meta, e)}` : `${FormatarNumero(e.meta, e)}${e.medidaMeta ?? ''}`}</strong></Text>
                        <Text fontSize={'14px'}>Valor acumulado: <strong>{ValorAcumulado === 0 ? 'N/A' : (e.tipoMeta === 0 ? `${e.medidaMeta ?? ''}${FormatarNumero(ValorAcumulado, e)}` : `${FormatarNumero(ValorAcumulado, e)}${e.medidaMeta ?? ''}`)}</strong></Text>
                      </Flex>
                      <Flex flexDir={'column'}>
                        <CircularProgress value={ProgressoMeta} color={e.metaInversa ? ProgressoMeta < 100 ? 'red.400' : 'green.400' : 'green.400'} size={'60px'} height={'70px'}>
                          <CircularProgressLabel mt={'.325rem'} fontSize={'12px'}>{ProgressoMeta === 0 ? 'N/A' : `${ProgressoMeta.toFixed(1)}%`}</CircularProgressLabel>
                        </CircularProgress>
                      </Flex>
                    </Flex>
                  </Flex>
                  {e.periodos.length > 0 && <AccordionContainer title='Mostrar mais' hideHr>
                    <LoadingContainer linhas={18} loading={e.periodos.some(p => p.id === IsLoadingId)} my='1rem'>
                      <Flex pb={'1rem'} flexDir={'column'} gap={'1rem'}>
                        <Flex flexDir={'column'} gap={'1.25rem'}>
                          {e.periodosADM && e.periodosADM.length > 0 && e.isResponsavel && <Flex mt={'.5rem'} justifyContent={'end'}><SwitchCmp OnChange={setTabelaAdm} value={TabelaAdm} legendaEsquerda='Dados gerais' legendaDireita='Dados dos responsáveis'/></Flex>}
                          <Flex justifyContent={'space-between'}>
                            <Flex gap={'.75rem'}>
                              {e.responsavel && e.participantes && e.participantes?.length > 0 && <Flex gap={'.325rem'} w={'50%'} alignItems='center'>
                                <Text fontWeight={700} fontSize={'14px'}>Participantes:</Text>
                                <Flex gap={'.75rem'}>
                                  <AvatarGroup size='sm' max={5}>
                                    <Avatar src={e.responsavel?.avatar}/>
                                    {e.participantes?.filter(p => p.id !== e.responsavel?.id).map(p => {
                                      return (
                                        <Avatar src={p.avatar}/>
                                      )
                                    })}
                                  </AvatarGroup>
                                  <ButtonCmp onClick={() => setVisualizarParticipantes(e.id)} VarColor='Green2'>Ver mais</ButtonCmp>
                                </Flex>
                              </Flex>}
                            </Flex>
                            <div></div>
                            <HStack mt={0} fontSize={'.875rem'} gap={'.25rem'}>
                              <ButtonCmp small onClick={() => { window.location.href = `${urlApi}/Indicador/${e.id}/Csv?token=${getToken()}` }} leftIcon={<FaFileCsv/>} VarColor='Rosa'>Baixar CSV</ButtonCmp>
                              {(IsModerador || e.isResponsavel) && (e.status === 1 || e.status === 3 || e.status === 5) && <ButtonCmp small onClick={() => setModalSubirEmMassaIsOpen(e.id)} leftIcon={<FaFileCsv/>} VarColor='Green2'>Subir dados em massa</ButtonCmp>}
                              <Flex alignItems={'center'} gap={'.325rem'}>
                                <Text>Ano:</Text>
                                <Text>{e.periodos[(PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0)].ano}</Text>
                              </Flex>
                              {e.periodos.length > 1 && <><ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => { PrevPage(e.id) }} VarColor='Green2'><FaArrowLeft/></ButtonCmp>
                              <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => { NextPage(e.id) }} VarColor='Green2'><FaArrowRight/></ButtonCmp></>}
                            </HStack>
                          </Flex>
                        </Flex>
                        {!TabelaAdm && <>
                          {!!PeriodoSelecionado?.find(a => a.id === e.id)?.val &&
                          <>
                            <Tabela
                              onUpdate={(id) => GetIndicadores(id)}
                              periodosSugestao={e.periodosSugestao}
                              status={e.status}
                              tipo={e.tipoMeta}
                              // acumuladoAnterior={GetValorAcumuladoAnterior(e.id, e.periodos)}
                              periodo={e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0]}
                              isResponsavel={e.isResponsavel}
                            />
                            <Flex alignItems={'center'} gap='.5rem'>
                              <BsGraphUp color='var(--Azul)' size={20}/>
                              <h3>Gráfico de {e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0].ano}</h3>
                            </Flex>
                            <IndicadorChart
                              indicador={e}
                              data={e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0]?.meses}
                              id={'indicador-chart' + '-' + `${e.id}`}
                              // valorMaximo={e.meta}
                            />
                          </>}
                          {!PeriodoSelecionado?.find(a => a.id === e.id)?.val &&
                            <>
                              <Tabela
                                onUpdate={(id) => GetIndicadores(id)}
                                periodosSugestao={e.periodosSugestao}
                                status={e.status}
                                tipo={e.tipoMeta}
                                // acumuladoAnterior={GetValorAcumuladoAnterior(e.id, e.periodos)}
                                periodo={e.periodos[0]}
                                isResponsavel={e.isResponsavel}
                              />
                              <Flex alignItems={'center'} gap='.5rem'>
                                <BsGraphUp color='var(--Azul)' size={20}/>
                                <h3>Gráfico de {e.periodos[0].ano}</h3>
                              </Flex>
                              <IndicadorChart
                                indicador={e}
                                data={e.periodos[0]?.meses}
                                id={'indicador-chart' + '-' + `${e.id}`}
                                // valorMaximo={e.meta}
                              />
                            </>
                          }
                        </>}
                        {TabelaAdm && e.periodosADM && <>
                          {!!PeriodoSelecionado?.find(a => a.id === e.id)?.val &&
                          <>
                            <Tabela
                              onUpdate={(id) => GetIndicadores(id)}
                              periodosSugestao={[]}
                              status={e.status}
                              tipo={e.tipoMeta}
                              // acumuladoAnterior={GetValorAcumuladoAnterior(e.id, e.periodos)}
                              periodo={e.periodosADM[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0]}
                              periodoGeralId={e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0].id}
                              isResponsavel={e.isResponsavel}
                            />
                          </>}
                          {!PeriodoSelecionado?.find(a => a.id === e.id)?.val &&
                            <>
                              <Tabela
                                onUpdate={(id) => GetIndicadores(id)}
                                periodosSugestao={e.periodosSugestao}
                                status={e.status}
                                tipo={e.tipoMeta}
                                // acumuladoAnterior={GetValorAcumuladoAnterior(e.id, e.periodos)}
                                periodo={e.periodosADM[0]}
                                periodoGeralId={e.periodos[0].id}
                                isResponsavel={e.isResponsavel}
                              />
                            </>
                          }
                        </>}
                      </Flex>
                    </LoadingContainer>
                    {!TabelaAdm && e.periodos?.length >= 2 && <Flex flexDir={'column'} gap='1rem'>
                      <Divider my={'.5rem'} opacity={'.8'} borderColor={'var(--Azul)'}/>
                      <Flex justifyContent={'space-between'} alignItems='center'>
                        <Flex alignItems={'center'} gap='.5rem'>
                          <BsGraphUp color='var(--Azul)' size={20}/>
                          <h3>Gráfico de períodos</h3>
                        </Flex>
                        <Flex gap={'.75rem'}>
                          <SelectCmp w={'8rem'} OnChange={(val) => OnChangeInicial(e.id, parseInt(val))} value={PeriodosFiltro.find(e => e.id)?.periodoInicial?.toString() ?? (e.periodos[0].ano.toString())} title='Ano inicial'>
                            {e.periodos.map(p => {
                              return <option value={p.ano.toString()}>{p.ano}</option>
                            })}
                          </SelectCmp>
                          <SelectCmp w={'8rem'} OnChange={(val) => OnChangeFinal(e.id, parseInt(val))} value={PeriodosFiltro.find(e => e.id)?.periodoFinal?.toString() ?? (e.periodos[e.periodos.length - 1].ano.toString())} title='Ano final'>
                            {e.periodos.map(p => {
                              return <option value={p.ano.toString()}>{p.ano}</option>
                            })}
                          </SelectCmp>
                        </Flex>
                      </Flex>
                      {isLoadingFiltro === e.id ? <LoadingContainer loading linhas={12}></LoadingContainer> : <IndicadorVariosPeriodosChart
                        indicador={e}
                        periodoFinal={PeriodosFiltro.find(e => e.id)?.periodoFinal}
                        periodoInicial={PeriodosFiltro.find(e => e.id)?.periodoInicial}
                        id={'indicador-chart' + '-' + `${e.id}`}
                        // valorMaximo={e.meta}
                      />}
                    </Flex>}
                    {!TabelaAdm && <Flex flexDir={'column'} gap='1rem' pb={'1.5rem'}>
                      <Divider my={'.5rem'} opacity={'.8'} borderColor={'var(--Azul)'}/>
                      <Flex justifyContent={'space-between'} alignItems='center'>
                        <Flex alignItems={'center'} gap='.5rem'>
                          <BsGraphUp color='var(--Azul)' size={20}/>
                          <h3>Gráfico comparativo</h3>
                        </Flex>
                        <ButtonCmp VarColor='Green2' onClick={() => { setGerarComparativoIsOpen(true); setEditId(e.id) }}>Gerar gráfico comparativo</ButtonCmp>
                      </Flex>
                      {isLoadingFiltro === e.id ? <LoadingContainer loading linhas={12}>
                      </LoadingContainer> : ComparativoChart.find(c => c.id === e.id) ? <IndicadorVariosPeriodosChart
                          indicador={ComparativoChart.find(c => c.id === e.id)}
                          periodoFinal={ComparativoChart.find(c => c.id === e.id)?.periodos[(ComparativoChart.find(c => c.id === e.id)?.periodos?.length ?? 1) - 1].ano}
                          periodoInicial={ComparativoChart.find(c => c.id === e.id)?.periodos[0].ano}
                          id={'indicador-comparativo-chart' + '-' + `${e.id}`}
                          // valorMaximo={e.meta}
                        /> : <Flex justifyContent={'center'} alignItems={'center'} py={'4rem'} fontWeight={700} fontSize={'14px'}>
                        Clique no botão verde acima para gerar um gráfico comparativo com outros indicadores
                      </Flex>}
                    </Flex>}
                  </AccordionContainer>}
                </>}
                {VerificarMudanca?.id === e.id && <Flex flexDir={'column'}>
                  <Flex flexDir={'column'} gap={'2rem'}>
                    {e.periodos.map(p => {
                      const periodosSugestao = e.periodosSugestao?.find(ps => ps.ano === p.ano)
                      if (!periodosSugestao) return null
                      return (
                        <Flex flexDir={'column'} w={'100%'} gap={'.5rem'} mb={'1rem'}>
                          <Flex gap={'1rem'}>
                            <Flex fontSize={'14px'} lineHeight={'16px'} gap={'.25rem'} alignItems='center'><Text fontWeight={700}>Ano: {p.ano} -</Text><Text fontWeight={'400'}> Selecione os campos que você aprova a sugestão de alteração</Text></Flex>
                          </Flex>
                          <Grid gridTemplateColumns={'repeat(4, 1fr)'} gap={'.75rem 0'}>{p.meses.map(m => {
                            const mesSugestao = periodosSugestao?.meses.find(ms => ms.numeroMes === m.numeroMes)
                            return (
                              <Flex flexDir={'column'}>
                                <Text fontSize={'14px'}>{mesesAbreviados[m.numeroMes - 1]}</Text>
                                <Flex alignItems={'center'}>
                                  {e.isResponsavel && ((mesSugestao?.real && mesSugestao.real !== m.real)
                                    ? <Flex gap={'.125rem'} mr={'.325rem'} fontSize={'18px'}>
                                    <IoMdCloseCircle onClick={() => OnChangeMesAprovado(false, m.id, 'real')} cursor={'pointer'} color={MesesAprovados.find(e => e.id === m.id)?.real ? 'var(--c4)' : 'var(--Red)'}/> <IoCheckmarkCircle onClick={() => OnChangeMesAprovado(true, m.id, 'real')} cursor={'pointer'} color={MesesAprovados.find(e => e.id === m.id)?.real ? 'var(--Green2)' : 'var(--c4)'}/>
                                  </Flex>
                                    : <></>)}
                                  <Flex fontSize={'14px'} gap={'.325rem'}>
                                    <Text>Real: </Text>
                                    {(mesSugestao?.real && mesSugestao.real !== m.real) ? <Text color={mesSugestao?.real > (m.real ?? 0) ? 'var(--Green2)' : 'var(--Red)'}>{FormatarNumero(m.real ?? 0, e)} {'➞'} {FormatarNumero(mesSugestao?.real, e)}</Text> : <Text color={'var(--c6)'}>Manteve</Text>}
                                  </Flex>
                                </Flex>
                                <Flex alignItems={'center'}>
                                  {e.isResponsavel && ((mesSugestao?.previsto && mesSugestao.previsto !== m.previsto)
                                    ? <Flex gap={'.125rem'} mr={'.325rem'} fontSize={'18px'}>
                                    <IoMdCloseCircle onClick={() => OnChangeMesAprovado(false, m.id, 'previsto')} cursor={'pointer'} color={MesesAprovados.find(e => e.id === m.id)?.previsto ? 'var(--c4)' : 'var(--Red)'}/> <IoCheckmarkCircle onClick={() => OnChangeMesAprovado(true, m.id, 'previsto')} cursor={'pointer'} color={MesesAprovados.find(e => e.id === m.id)?.previsto ? 'var(--Green2)' : 'var(--c4)'}/>
                                  </Flex>
                                    : <></>)}
                                  <Flex fontSize={'14px'} gap={'.325rem'}>
                                  <Text>Previsto: </Text>
                                    {(mesSugestao?.previsto && mesSugestao.previsto !== m.previsto) ? <Text color={mesSugestao?.previsto > (m.previsto ?? 0) ? 'var(--Green2)' : 'var(--Red)'}>{FormatarNumero(m.previsto ?? 0, e)} {'➞'} {FormatarNumero(mesSugestao?.previsto, e)}</Text> : <Text color={'var(--c6)'}>Manteve</Text>}
                                  </Flex>
                                </Flex>
                              </Flex>
                            )
                          })}</Grid>
                          {e.isResponsavel && <Flex justifyContent={'end'}>
                            <ButtonCmp small VarColor='Green2' onClick={() => AprovarAlteracoes(p.indicadorId, p.ano)}>Mandar alterações</ButtonCmp>
                          </Flex>}
                        </Flex>
                      )
                    })}
                  </Flex>
                </Flex>}
              </Flex>
            )
          })}
        </Grid>}
      </LoadingContainer>
    </Body>
  )
}
