/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { iMesPeriodo, iIndicador } from '../..'

interface BarChartProps {
  id: string
  // valorMaximo: number
  data: iMesPeriodo[]
  indicador: iIndicador
}

export const IndicadorChart: React.FC<BarChartProps> = ({ id, data, indicador }) => {
  function GetAcumulado(index: number): number {
    let response = 0
    if (indicador.tipoMeta === 5) {
      return data[index].real ?? 0
    }
    for (let i = 0; i < index + 1; i++) {
      response += data[i].real ?? 0
    }

    return response
  }

  function FormatarNumero(num: number, indicador: iIndicador): string {
    let response = ''
    const toFixed = indicador.tipoMeta === 5 || indicador.tipoMeta === 0

    const numero = toFixed ? num.toFixed(2) : num.toString()

    const values = numero.split('.')

    for (let i = 0; i < (values[0].length / 3); i++) {
      for (let index = 1; index < 4; index++) {
        if (values[0][values[0].length - index - (i * 3)]) {
          response = values[0][values[0].length - index - (i * 3)] + response
        }
      }
      if ((i + 1) < (values[0].length / 3)) {
        response = '.' + response
      }
    }

    if (toFixed) {
      response += `,${values[1]}`
    }
    return response
  }
  const mesesAbreviados = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]
  function GerarGrafico(): void {
    const options = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        zoom: {
          enabled: false
        }
      },
      markers: {
        size: 6
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#27AE60', '#F2994A'],
      series: [
        {
          name: 'Valor Acumulado',
          data: data.map((e, i) => GetAcumulado(i))
        },
        {
          name: 'Valor Previsto',
          data: data.map((e) => e.previsto)
        }
      ],
      stroke: {
        width: [4, 4]
      },
      plotOptions: {
        bar: {
          columnWidth: '20%'
        }
      },
      // yaxis: {
      //   max: valorMaximo
      // },
      xaxis: {
        categories: data.map(e => e.numeroMes).map(e => mesesAbreviados[e - 1])
      },
      yaxis: {
        labels: {
          formatter: function(value: any) {
            return indicador.tipoMeta === 0 ? `${indicador.medidaMeta ?? ''}${FormatarNumero(value, indicador)}` : `${FormatarNumero(value, indicador)}${indicador.medidaMeta ?? ''}`
          }
        },
        min: data.some(e => (e.previsto && e.previsto < 0) || (e.real && e.real < 0)) ? undefined : 0
      },
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false
        },
        y: {
          show: true,
          formatter: function(value: any) {
            return indicador.tipoMeta === 0 ? `${indicador.medidaMeta ?? ''}${FormatarNumero(value, indicador)}` : `${FormatarNumero(value, indicador)}${indicador.medidaMeta ?? ''}`
          }
        }
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
        itemMargin: {
          horizontal: 8,
          vertical: 4
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])
  return (
      <Flex id={`chart-${id}`} />
  )
}
