/* eslint-disable no-unneeded-ternary */
import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { iCompetencia, iDefinicaoPorColab, iModalPadrao } from '../../../../../interfaces'
import { ModalBody, ModalFooter, ImgVariant } from './styles'
import { ModalBase } from '../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { appApi } from '../../../../../services/appApi'
import { ModalTodasCompetencias } from '../ModalTodasCompetencias'
import { FaTrash } from 'react-icons/fa'
import { UpdateElementByPosition } from '../../../../../Utils/Helper'

interface iProps extends iModalPadrao {
  avaliacaoId?: string
  pesquisaIniciada: boolean
  onSave: (avaliacaoId: string, definicoes: iDefinicaoPorColab[]) => void
}

export const ModalJaTrabalhadas: React.FC<iProps> = ({ OnCloseModal, isOpen, avaliacaoId, pesquisaIniciada, onSave }) => {
  const [Competencias, setCompetencias] = useState<iCompetencia[]>([])
  const [ModalTodasIsOpen, setModalTodasIsOpen] = useState(false)
  const [RecentementeColocado, setRecentementeColocado] = useState(false)
  const toast = useToast()

  function GetRecentementeColocado(id: string): void {
    appApi.get(`AvaliacaoPesquisa/${id}/RecentementeColocado`)
      .then(res => setRecentementeColocado(res.data))
      .catch(err => console.log(err))
  }

  function GetCompetenciasTrabalhadas(id: string): void {
    appApi.get(`AvaliacaoPesquisa/${id}/CompetenciasTrabalhadas`)
      .then(res => setCompetencias(res.data))
      .catch(err => console.log(err))
  }

  function OnChangeCompetencia(compsNova: string[]): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId}/MudarCompetencia`, { list: compsNova })
      .then(res => {
        toast({
          title: 'Competência atualizada com sucesso',
          status: 'success',
          position: 'top',
          duration: 4000
        })
        setModalTodasIsOpen(false)
        GetCompetenciasTrabalhadas(avaliacaoId ?? '')
      })
      .catch(err => console.log(err))
  }

  function OnDeleteCompetencia(compAtual: string): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId}/RemoverCompetencia?atual=${compAtual}`)
      .then(res => {
        toast({
          title: 'Competência removida com sucesso',
          status: 'success',
          position: 'top',
          duration: 4000
        })
        setModalTodasIsOpen(false)
        GetCompetenciasTrabalhadas(avaliacaoId ?? '')
      })
      .catch(err => console.log(err))
  }

  function onSetDefinicao(compId: string, questId: string): void {
    const compIndex = Competencias.findIndex(e => e.id === compId)
    const newComp = Competencias[compIndex]
    if (newComp.definicao) {
      newComp.definicao.competenciaId = compId
      newComp.definicao.questaoId = questId
    }
    setCompetencias(UpdateElementByPosition(Competencias, compIndex, newComp))
  }

  useEffect(() => {
    if (avaliacaoId) {
      GetCompetenciasTrabalhadas(avaliacaoId)
      GetRecentementeColocado(avaliacaoId)
    }
  }, [avaliacaoId])

  return (
    <ModalBase
      isOpen={isOpen as boolean}
      Titulo='Competências utilizadas no último PDI'
      Subtitulo='Selecione também uma questão para ser utilizada como definição para a competência'
      onClose={OnCloseModal}
      Width='65rem'
    >
      <ModalTodasCompetencias
        isOpen={ModalTodasIsOpen}
        OnCloseModal={() => setModalTodasIsOpen(false)}
        onConfirm={OnChangeCompetencia}
        idsIgnorar={Competencias.map(e => e.id)}
      />
      <ModalBody>
        <Flex flexDir={'column'}>
          {Competencias.length === 0 && <Flex justifyContent={'center'} fontSize='14px' fontWeight={700} color={'var(--Azul)'} py={'.813rem'}>Esse colaborador não possuí nenhuma competência selecionada</Flex>}
          {
            Competencias.map((e: iCompetencia, i) => {
              return (
                <Flex flexDir={'column'}>
                  <Flex key={i} className='VariantColor' justifyContent={'space-between'}>
                    <Flex>
                      <ImgVariant src={e.icone} />
                      <Flex flexDirection={'column'}>
                        <h4>{e.nome}</h4>
                        <small>{e.doSistema ? 'Padrão' : e.dataCriacao}</small>
                      </Flex>
                    </Flex>

                    {(!pesquisaIniciada || RecentementeColocado) && <Flex alignItems={'center'} gap={'.5rem'}>
                      <ButtonCmp VarColor='Red' onClick={() => OnDeleteCompetencia(e.id)}>
                        <FaTrash cursor={'pointer'}/>
                      </ButtonCmp>
                    </Flex>}
                  </Flex>
                  <Flex flexDir={'column'} gap={'.813rem'} p={'1rem'}>
                    {e.questoes.map(q => {
                      const isSelected = e.definicao?.questaoId === q.id
                      if (!pesquisaIniciada || RecentementeColocado) {
                        return <Flex fontSize={'14px'} opacity={isSelected ? '1' : '.6'} borderRadius={'.325rem'} cursor={'pointer'} bg={isSelected ? 'var(--Green2OpacoBg)' : 'var(--c2)'} p={'.625rem'} border={`2px dashed ${isSelected ? 'var(--Green2)' : 'var(--c6)'}`} fontWeight={isSelected ? '700' : '400'} pos={'relative'} _hover={isSelected ? {} : { background: 'var(--AzulOpacoBg)', borderColor: 'var(--Azul)', opacity: '0.9' }} onClick={() => onSetDefinicao(e.id, q.id)}>
                          {q.enunciado}
                        </Flex>
                      }
                      return <Flex fontSize={'14px'} opacity={isSelected ? '1' : '.6'} borderRadius={'.325rem'} bg={isSelected ? 'var(--Green2OpacoBg)' : 'var(--c2)'} p={'.625rem'} border={`2px dashed ${isSelected ? 'var(--Green2)' : 'var(--c6)'}`} fontWeight={isSelected ? '700' : '400'} pos={'relative'}>
                        {q.enunciado}
                      </Flex>
                    })}
                  </Flex>
                </Flex>
              )
            })
          }
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ButtonCmp VarColor='c6' mr={'.5rem'} onClick={() => OnCloseModal()}>Voltar</ButtonCmp>
        {(!pesquisaIniciada || RecentementeColocado) && <ButtonCmp mr={'.5rem'} onClick={() => setModalTodasIsOpen(true) } VarColor='Rosa'>Adicionar uma nova</ButtonCmp>}
        {(!pesquisaIniciada || RecentementeColocado) && <ButtonCmp onClick={() => onSave(avaliacaoId ?? '', Competencias.map(e => e.definicao ?? { colaboradorId: '' }))} VarColor='Green2'>Salvar</ButtonCmp>}
      </ModalFooter>
    </ModalBase>
  )
}
