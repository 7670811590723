/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Divider, Flex, Img, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineStop } from 'react-icons/ai'
import { FaChartBar, FaCheckCircle, FaClipboardList, FaCog, FaRegPlayCircle, FaRegStopCircle, FaRegTimesCircle } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { parseJwt } from '../../../../../services/token'
import { CardAvaliado } from '../CardAvaliado'
import { ColumnText } from '../CardAvaliado/styles'

import imgAvaliadores from '../../../../../assets/IAvaliadores.svg'
import { PropInvalida, SubstringRoute } from '../../../../../Utils/Helper'
import { useLocation, useNavigate } from 'react-router-dom'
import { iAvaliado, iAvaliadoCiclica } from '../..'

interface iProps {
  avaliacao: iAvaliadoCiclica
  pesquisaEncerrada: boolean
  onEncerrar: (id: string) => void
  onReabrir: (id: string) => void
  openConfiguracao?: (avaliado: iAvaliado) => void
  onOpenModalReabrir: (id: string) => void
  onOpenModalInterromper: (id: string) => void
}

export const CardAvaliadoCiclico: React.FC<iProps> = ({ avaliacao, pesquisaEncerrada, onEncerrar, onReabrir, openConfiguracao, onOpenModalReabrir, onOpenModalInterromper }) => {
  const [IsDisabled, setIsDisabled] = useState(false)
  const interrompido = avaliacao.avaliacoes?.some(e => e.interrompida)
  const nav = useNavigate()
  const { pathname } = useLocation()

  function ObterChildren(): any {
    if (avaliacao.principal === undefined) return

    const obj = avaliacao.principal.statusCiclica
    if (!obj || (obj.cor === '' && obj.data === '' && obj.texto === '')) return

    return (
        <>
            <Flex alignItems={'center'} gap='.5rem' minW={'11.5rem'} width={'11.5rem'}>
                <FaClipboardList color={obj.cor} size={28} />
                <Flex flexDir={'column'}>
                    <Text color={obj.cor} fontWeight={'700'} fontSize={'.813rem'}>{obj.texto}</Text>
                    <Text color={obj.cor} fontSize={'0.813rem'}>{obj.data}</Text>
                </Flex>
            </Flex>
            <Divider orientation='vertical' borderColor={'var(--Gray4)'} height={'2.5rem'} />
        </>
    )
  }

  useEffect(() => {
    if (avaliacao.principal) {
      const obj = avaliacao.principal.statusCiclica
      if (obj?.cor !== '') {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      setIsDisabled(false)
    }
  }, [avaliacao.principal])

  if (IsDisabled) {
    return null
  }
  return (
        <Accordion allowToggle >
            <AccordionItem>
                {({ isExpanded }) => (
                    <>
                        <h2>
                            <Divider borderColor={'var(--Azul)'} />
                            <AccordionButton color={'black'} bg={isExpanded ? 'var(--AzulOpaco3)' : 'var(--AzulOpacoBg)'} _hover={{ backgroundColor: 'var(--AzulOpaco3)' }}>
                                <Box gap={'2rem'} alignItems={'center'} width={'100%'} display={'flex'} textAlign='left'>
                                    <Flex alignItems={'center'} gap={'1rem'} flex={'1'}>
                                        <Avatar w={'2.325rem'} h={'2.325rem'} src={avaliacao.principal?.colaborador.avatar} name={avaliacao.principal?.colaborador.nome} />
                                        <Flex flexDirection={'column'}>
                                            <span style={{ fontSize: '14px', fontWeight: 700, lineHeight: '16px' }}>{avaliacao.principal?.colaborador.nome}</span>
                                            <span style={{ fontSize: '12px', lineHeight: '14px' }}>Área: {avaliacao.principal?.colaborador.nomeDepartamento}</span>
                                            {avaliacao.principal?.colaborador.gestorNome && <span style={{ fontSize: '12px', lineHeight: '14px' }}>Gestor: {avaliacao.principal?.colaborador.gestorNome}</span>}
                                        </Flex>
                                    </Flex>

                                    {!interrompido &&
                                        <Flex gap='1rem' alignItems={'center'} flex={'1'}>
                                            <Flex alignItems={'center'}>
                                                <Img width={'2rem'} height={'2rem'} src={imgAvaliadores} />
                                                <ColumnText>
                                                    <h3 style={{ lineHeight: '90%' }}>{avaliacao.principal?.totalAvaliadores}</h3>
                                                    <Text fontSize={'.75rem'}>Avaliadores</Text>
                                                </ColumnText>
                                            </Flex>

                                            <Divider orientation='vertical' borderColor={'var(--Gray4)'} height={'2.5rem'} />
                                            {ObterChildren()}

                                            {(avaliacao.principal?.autoavaliacao !== null || avaliacao.principal.gestor !== null) && (
                                                <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'}>
                                                    {
                                                        (avaliacao.principal?.autoavaliacao !== null) && (
                                                            <Flex marginBottom={'.3rem'} alignItems='center'>
                                                                {(avaliacao.principal?.autoavaliacao) && <FaCheckCircle color='var(--Green2)' style={{ marginRight: '.3rem' }} />}
                                                                {!avaliacao.principal?.autoavaliacao && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                                                <small>Autoavaliação</small>
                                                            </Flex>
                                                        )
                                                    }
                                                    {
                                                        (avaliacao.principal?.gestor !== null) && (
                                                            <Flex marginBottom={'.3rem'} alignItems='center'>
                                                                {avaliacao.principal?.gestor && <FaCheckCircle color='var(--Green2)' style={{ marginRight: '.3rem' }} />}
                                                                {!avaliacao.principal?.gestor && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                                                <small>Gestor</small>
                                                            </Flex>
                                                        )
                                                    }
                                                </Flex>
                                            )}
                                        </Flex>
                                    }

                                    <Flex alignItems={'center'} justifyContent={'end'} gap={'1rem'} flex={'1'} w='13.813rem'>
                                        {!interrompido &&
                                            <Flex gap={'.5rem'}>
                                                {
                                                    (((!avaliacao.principal?.encerrada && !PropInvalida(avaliacao.principal?.dataInicio) && avaliacao.principal?.iniciada) ?? (!avaliacao.principal?.encerrada && PropInvalida(avaliacao.principal?.dataInicio))) && parseJwt().adm === 'True') && (
                                                        <ButtonCmp
                                                            small
                                                            leftIcon={<FaRegStopCircle />}
                                                            VarColor='Red'
                                                            onClick={() => onEncerrar(avaliacao.principal?.id ?? '')}
                                                        >Encerrar</ButtonCmp>
                                                    )
                                                }

                                                {
                                                    ((!PropInvalida(avaliacao.principal?.dataInicio) && !avaliacao.principal?.encerrada)) && parseJwt().adm === 'True' && (
                                                        <ButtonCmp
                                                            small
                                                            rightIcon={<FaCog />}
                                                            VarColor='Green2'
                                                            onClick={() => {
                                                              if (openConfiguracao !== undefined) {
                                                                openConfiguracao(avaliacao.principal ?? {} as iAvaliado)
                                                              }
                                                            }}
                                                        >Configurações</ButtonCmp>
                                                    )
                                                }

                                                {
                                                    (avaliacao.principal?.encerrada && parseJwt().adm === 'True' && !interrompido) && (
                                                        <ButtonCmp
                                                            small
                                                            leftIcon={<FaRegPlayCircle />}
                                                            VarColor='Rosa'
                                                            onClick={() => {
                                                              if (!pesquisaEncerrada) {
                                                                onReabrir(avaliacao.principal?.id ?? '')
                                                              } else {
                                                                onOpenModalReabrir(avaliacao.principal?.id ?? '')
                                                              }
                                                            }}
                                                        >Reabrir</ButtonCmp>
                                                    )
                                                }

                                                {
                                                    avaliacao.principal?.encerrada &&
                                                    (((parseJwt().cid === avaliacao.principal?.colaborador.id && avaliacao.principal?.liberarResultado === 0) ||
                                                        parseJwt().cid === avaliacao.principal?.colaborador.gestorId) ||
                                                        parseJwt().role === '2' || parseJwt().adm === 'True') &&
                                                    (
                                                        <ButtonCmp
                                                            small
                                                            VarColor='Green2'
                                                            leftIcon={<FaChartBar />}
                                                            onClick={() => nav(`${SubstringRoute(pathname, 'ResultadoPesquisa')}ResultadoIndividual/${avaliacao.principal?.id}`)}
                                                            disabled={avaliacao.principal?.totalRespostas === 0 || (avaliacao.principal?.autoavaliacao && avaliacao.principal?.totalRespostas === 1)}
                                                        >Resultados</ButtonCmp>
                                                    )
                                                }
                                            </Flex>
                                        }
                                        <Flex alignItems={'center'} gap={'.25rem'}>
                                            {avaliacao.avaliacoes.some(e => e.interrompida) && <AiOutlineStop size='24' color='red' />}
                                            <Text fontSize={'12px'}>{isExpanded ? 'Minimizar' : 'Maximizar'}</Text>
                                            <AccordionIcon />
                                        </Flex>
                                    </Flex>
                                </Box>
                            </AccordionButton>
                            <Divider borderColor={'var(--Azul)'} />
                        </h2>
                        <Flex flexDir={'column'}>
                          {
                              avaliacao.avaliacoes.map((c, i2) => {
                                return (
                                      <AccordionPanel key={i2} px={'.5rem'} pt={'.75rem'} pb={'0'}>
                                          <CardAvaliado
                                              onEncerrar={onEncerrar}
                                              onReabrir={onReabrir}
                                              openConfiguracao={openConfiguracao}
                                              onOpenModalReabrir={onOpenModalReabrir}
                                              interromperPesquisa={avaliacao.avaliacoes.some(e => e.interrompida)}
                                              key={i2}
                                              avaliado={c}
                                              avaliacaoAnterior={avaliacao.avaliacoes[i2 - 1]}
                                              proximaAvaliacao={avaliacao.avaliacoes[i2 + 1]}
                                          />
                                      </AccordionPanel>
                                )
                              })
                          }
                        </Flex>
                        <AccordionPanel pb={4}>
                            {
                                !interrompido && parseJwt().adm === 'True' &&
                                (
                                    <ButtonCmp
                                        leftIcon={<FaRegStopCircle />}
                                        VarColor="Red"
                                        onClick={() => onOpenModalInterromper(avaliacao.principal?.id ?? '')}
                                        size="sm"
                                        mr="2"
                                    >
                                        Interromper pesquisa
                                    </ButtonCmp>
                                )
                            }
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </Accordion>
  )
}
